const envURLs = [
  {
    key: "dev",
    matcher: /-dev/i,
    value: "https://r10-dev.digitalretail.vodafone.com/opw-middleware",
  },
  {
    key: "test",
    matcher: /-test/i,
    value: "https://r10-test.digitalretail.vodafone.com/opw-middleware",
  },
  {
    key: "preprod",
    matcher: /-preprod/i,
    value: "https://opw-preprod.digitalretail.vodafone.com/opw-middleware",
  },
  {
    key: "prod",
    matcher: /-prod/i,
    value: "https://de-gs-prod.app-prod.digitalretail.vodafone.com",
  },
];

const getEnvURL = (url = "") => {
  const segment = url.toLowerCase().split(".", 1)?.[0];
  return envURLs.find(({ matcher }) => segment?.match(matcher))?.value;
};

export function getMiddlewareBaseUrl(settings) {
  const appBuilderUrl = settings.ingressURL || settings.apiUrl;
  const baseURL = getEnvURL(appBuilderUrl);
  return `${baseURL}/app-builder`;
}
